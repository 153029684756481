import { defineStore } from "pinia";

export const useSystemStore = defineStore("SystemStore", {
  state: () => ({
    isLoading: false,
  }),
  getters: {
  },
  actions: {
    setIsloading() {
      this.isLoading = !this.isLoading
    }
  },
});